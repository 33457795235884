function initMenu() {

		const submenuToggles = document.querySelectorAll('.submenu-toggle');
		submenuToggles.forEach(toggle => {
			toggle.addEventListener('click', function(e) {
				e.preventDefault();

				const parentLi = this.closest('li');
				const isActive = this.classList.contains('active');

				closeSubmenusAtSameLevel(parentLi);

				if (!isActive) {
					this.classList.add('active');
					const submenu = this.nextElementSibling;
					if (submenu && submenu.classList.contains('submenu')) {
						submenu.classList.add('active');
					}
				}
			});
		});

		function closeSubmenusAtSameLevel(currentLi) {
			const siblingLis = currentLi.parentElement.children;
			Array.from(siblingLis).forEach(sibling => {
				const submenuToggle = sibling.querySelector('.submenu-toggle.active');
				if (submenuToggle) {
					submenuToggle.classList.remove('active');
					const submenu = submenuToggle.nextElementSibling;
					if (submenu && submenu.classList.contains('submenu')) {
						submenu.classList.remove('active');
					}
				}
			});
		}

	const menu = document.querySelector('.app-nav-menu');
	document.addEventListener('click', function(event) {
		if (!menu.contains(event.target)) {
			closeAllSubmenus();
		}
	});

	function closeAllSubmenus() {
		const activeToggles = document.querySelectorAll('.submenu-toggle.active');
		activeToggles.forEach(toggle => {
			toggle.classList.remove('active');
			const submenu = toggle.nextElementSibling;
			if (submenu && submenu.classList.contains('submenu')) {
				submenu.classList.remove('active');
			}
		});
	}
}
