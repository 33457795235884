function initPage() {
	$(".form-select").selectmenu();

	$(".app-login-password-toggle").on('click', function () {
		$(this).toggleClass('active');
		const input = $('.app-login-input-password');
		input.attr('type', input.attr('type') === 'password' ? 'text' : 'password');
	});

	const forms = document.querySelectorAll('.needs-validation')
	Array.prototype.slice.call(forms)
	.forEach(function (form) {
		form.addEventListener('submit', function (event) {
			if (!form.checkValidity()) {
				event.preventDefault()
				event.stopPropagation()
			}

			form.classList.add('was-validated')
		}, false)
	});

	const tables = document.querySelectorAll(".table-container-large table");

	tables.forEach((table) => {
		const rows = table.querySelectorAll("tr");

		let stickyOffset = 0;

		const firstRowCells = rows[0].querySelectorAll(".col-sticky");

		firstRowCells.forEach((cell, index) => {
			cell.style.left = `${stickyOffset}px`;
			cell.style.zIndex = 2;

			stickyOffset += cell.offsetWidth;

			rows.forEach(row => {
				const stickyCell = row.querySelectorAll(".col-sticky")[index];
				if (stickyCell) {
					stickyCell.style.left = `${stickyOffset - cell.offsetWidth}px`;
					stickyCell.style.zIndex = 1;
				}
			});
		});
	});
}
